import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export function formatExpDate(date) {
  if (date == '' || date == '-') return "-"
  let dayjsDate = dayjs(date, 'DD/MM/YYYY')
  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date, 'YYYYMMDD');
  }
  return dayjsDate.format("MMM/YYYY")
}

export function fullExpDate(date) {
  if (date == '' || date == '-') return "-"
  let dayjsDate = dayjs(date, 'DD/MM/YYYY')
  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date, 'YYYYMMDD');
  }
  return dayjsDate.format("DD/MMM/YYYY")
}

export function formatExpNumber(date) {
  if (date == '' || date == '-') return "-"
  let dayjsDate = dayjs(date, 'DD/MM/YYYY')
  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date, 'YYYYMMDD');
  }
  return dayjsDate.format("MM/YYYY")
}

export function formatDMY(date) {
  if (date == '' || date == '-') return "-"
  let dayjsDate = dayjs(date, 'DD/MM/YYYY')
  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date, 'YYYYMMDD');
  }
  return dayjsDate.format("DD/MM/YYYY")
}

export function formatDayOfWeek(date) {
  if (date == '' || date == '-') return "-"
  let dayjsDate = dayjs(date, 'DD/MM/YYYY')
  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date, 'YYYYMMDD');
  }
  return dayjsDate.format("dddd")
}

export function formatThaiDate(date) {
  if (date == '' || date == '-') return "-"
  let dayjsDate = dayjs(date, 'DD/MM/YYYY')
  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date, 'YYYYMMDD');
  }
  return dayjsDate.add(543,'year').format("DD/MM/YYYY")
}